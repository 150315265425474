import React from "react";

const ErrorNotification = ({ message }) => {
  return (
    <div
      style={{
        backgroundColor: "red",
        color: "white",
        padding: "10px",
        borderRadius: "5px",
      }}
    >
      <p>
        ERROR! You Probably tried uploading non PDF file, please provide PDF
        file.{" "}
      </p>
      <p>Error: {message}</p>
    </div>
  );
};

export default ErrorNotification;
