import React from "react";

function ImageDisplay({ src, width, height, title }) {
  let style = {};
  let className = "preview-image";

  const openInNewTab = (base64Data) => {
    // Convert base64 to blob
    const byteCharacters = atob(base64Data.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" });

    // Create object URL and open it
    const url = URL.createObjectURL(blob);
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  if (width <= 32 && height <= 32) {
    style = {
      width: width * 5,
      height: height * 5,
      imageRendering: "pixelated",
    };
  } else if (width > 300 || height > 300) {
    className = "max-dimension";
  }

  return (
    <img
      src={src}
      style={style}
      title={title}
      alt="preview"
      className={className}
      onClick={() => openInNewTab(src)}
    />
  );
}

export default ImageDisplay;
