export const Development = {
  service_url: "http://localhost:5000",
  api: "/",
  get_api_url: () => {
    return `${Development.service_url}${Development.api}`;
  },
};

export const Production = {
  service_url: "https://review-tools.pagedout.institute",
  api: "/",
  get_api_url: () => {
    return `${Production.service_url}${Production.api}`;
  },
};
